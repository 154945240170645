<template>
  <div class="downloadContainer">
    <div class="logo"><img src="@/assets/images/logo.png" alt="猪耳朵"></div>
    <div class="slogan">猪耳朵 - 省时、省力、更省钱，聚合平台领跑者</div>
    <a :href="downloadUrl" class="btn" v-if="downloadUrl" @click="handleOpenAndroidGuide">下 载</a>
    <div v-show="guideMaskVisible" class="wechatguide">
      <img src="@/assets/images/android-guide.png" />
    </div>
  </div>
</template>
<script>
import { getMobileSystem, browser } from '@/utils'
import { http } from '@/http'

export default {
  data(){
    return {
      downloadUrl: '',
      guideMaskVisible: false
    }
  },
  created() {
    document.title = '猪耳朵商户APP下载'
    this.getDownloadUrl()
  },
  methods: {
    getDownloadUrl(){
      http.post('/?cmd=com.xqxc.api.pigear.biz.platform.MerRegSuccessService.getMerRegSuccess', {
        systemType: getMobileSystem()
      }).then(res=>{
        this.downloadUrl = res.result.appDownloadUrl
      })
    },
    handleOpenAndroidGuide(e) {
      if(browser.versions.weixin) {
        e.preventDefault()
        this.guideMaskVisible = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.downloadContainer{
    background: #FFF;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;
}
.logo{
    width: 2.5rem;
    height: 2.5rem;
    margin: 1.1rem auto 0 auto;
    overflow: hidden;
}
.logo img{
    width: 100%;
    height: 100%;
}
.slogan{
    height: 1.6rem;
    padding: .2rem;
    line-height: .3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .3rem;
    color: #333;
}
.btn{
    width: 2.5rem;
    height: .8rem;
    display: block;
    text-align: center;
    line-height: .8rem;
    background: #00abeb;
    color: #FFF;
    font-size: .3rem;
    border: none;
    border-radius: .1rem;
    margin: 0 auto;
}
.wechatguide{
    text-align: center; 
    z-index: 9; 
    padding: 0 .4rem; 
    box-sizing: border-box;
    position:fixed; 
    top:0; right:0; bottom:0; left:0; 
    background: rgba(0, 0, 0, .7);
}
</style>